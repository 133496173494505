<template>
  <my-farme :width="1920" :height="1080" bgColor="#eee">
    <div class="loginBox">
      <!--登录-->
      <div class="loginCon">
        <div class="logoImg">
          <img src="@/assets/images/login/logo_img.png" alt="" />
        </div>
        <div class="lcCon">
          <div class="lccTitle">欢迎来到远程守护值勤平台</div>
          <form action="#">
            <div class="lccCon">
              <div class="lcccItem">
                <img src="@/assets/images/login/login_icon_user.png" />
                <input
                  type="text"
                  v-model="loginParams.account"
                  placeholder="请输入账号"
                  autoComplete="“true”"
                />
              </div>
              <div class="lcccItem">
                <img src="@/assets/images/login/login_icon_key.png" />
                <input
                  type="password"
                  v-model="loginParams.password"
                  placeholder="请输入密码"
                  autoComplete="“true”"
                />
              </div>
            </div>
          </form>
          <div class="lccHint">
            <p class="left">
              <!-- 记住我 -->
              <el-checkbox v-model="checked" class="rememberMe"></el-checkbox>
              <!-- <img src="@/assets/images/login/login_kuang.png" /> -->
              <span>记住密码</span>
            </p>
            <!-- <p class="right" v-on:click="showFindPwd">忘记密码</p> -->
          </div>
          <div class="lccBtn" v-on:click="handleLogin">立即登录</div>
          <!-- <div class="lccTxt" v-on:click="showRegister">账号注册</div> -->
        </div>
      </div>
    </div>
  </my-farme>
</template>

<script>
import loginHttps from "../../https/login/login";
export default {
  data() {
    return {
      https: loginHttps,
      loginParams: {
        account: "",
        password: "",
      },
      checked: false,
    };
  },
  methods: {
    handleLogin() {
      const Base64 = require("js-base64").Base64;
      let that = this;
      if (that.loginParams.account == "" || that.loginParams.password == "") {
        return that.$message.error("账号或密码不能为空");
      } else {
        if (this.checked) {
          let password = Base64.encode(this.loginParams.password); // base64加密
          localStorage.setItem("userId", this.loginParams.account);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("userId");
          localStorage.removeItem("password");
        }
        loginHttps.login(that.loginParams).then((res) => {
          if (res.data.code == 200) {
            let userInfo = res.data.data.user;
            let token = "Beare " + res.data.data.token;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            // this.$store.dispatch('saveUserName',userInfo);
            localStorage.setItem("token", token);
            // if (userInfo.id == 1) {
            //如果为管理员
            that.$router.push({ path: "/login2" });
            // }
          } else {
            if (res.data.message == "用户不存在") {
              this.$message.error("用户不存在");
            } else if (res.data.message == "账号或密码错误") {
              this.$message.error("账号或密码错误");
            } else {
              return;
            }
          }
        });
      }
    },
  },
  created() {},
  mounted() {
    const Base64 = require("js-base64").Base64;
    let username = localStorage.getItem("userId");
    if (username) {
      this.loginParams.account = localStorage.getItem("userId");
      this.loginParams.password = Base64.decode(
        localStorage.getItem("password")
      ); // base64解密
      this.checked = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/login/index.scss";
</style>
